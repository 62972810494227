import i18n from 'i18n';
import React from 'react';
import i18next from 'i18next';
import { Trans, Translation, useTranslation } from 'react-i18next';
import _ from '@lodash';
import * as yup from 'yup';
import {
	Device,
	LicensedPolicyItem,
	Log,
	PolicyItem,
	PolicyItemLicensedSetting,
	PolicyItemScheduledSetting,
	RegScanSchedule,
	RegScanSchedulePolicyItem,
	RtScanPolicyItem
} from 'app/store/types';
import allPolicyItems, {
	adminpwrKey,
	disablePasswordModeOption,
	mode1PasswordModeOption,
	mode2PasswordModeOption
} from 'app/main/policies/allPolicyItems';
import moment from 'app/modules/moment';

// TODO::switch to `react-time-ago` component (need to solve importing locales)
export const daysAgo = (date: number) => {
	const numberOfDaysAgo = Math.round((new Date().getTime() - date) / (1000 * 60 * 60 * 24));
	switch (numberOfDaysAgo) {
		case 0:
			return <Translation>{t => t('dashboard:today')}</Translation>;
		case 1:
			return <Translation>{t => t('dashboard:yesterday')}</Translation>;
		default:
			return <Translation>{t => t('dashboard:days ago', { numberOfDaysAgo })}</Translation>;
	}
};

export const calculateDateRangeUnit = (start: Parameters<typeof moment>[0], end: Parameters<typeof moment>[0]) => {
	const endDate = moment(end);
	const startDate = moment(start);
	const diff = endDate.diff(startDate, 'days');

	if (diff > 40) return 'month';
	if (diff > 1) return 'day';
	return 'hour';
};

export const arrayToObjectBool = (array: string[]) => array.reduce((acc, key) => ({ ...acc, [key]: true }), {});

export const isValidEmail = (value: string) => yup.string().email().required().isValidSync(value);

export const getDeviceStatus = ({
	policyId,
	lastAssessment,
	secureStatus,
	pending,
	offline
}: Pick<Device, 'policyId' | 'lastAssessment' | 'secureStatus' | 'pending' | 'offline'>): Device['deviceStatus'] => {
	if (!policyId) {
		return 'noPolicy';
	}

	if (offline) {
		return 'offline';
	}

	if (!lastAssessment) {
		return 'notAssessed';
	}

	if (pending) {
		return 'pending';
	}

	if (secureStatus === 'pass') {
		return 'secure';
	}
	if (secureStatus === 'fail') {
		return 'notSecure';
	}

	// shouldn't be possible
	return 'notSecure';
};

export const getGenerationByFamily = (family: string): Device['generation'] => {
	// only use beginning of family name (for some reason it seems the family sometimes has a longer name)
	switch (family.split('_')[0]) {
		case 'Kronos':
		case 'Venus':
			return 'A4';
		case 'Amur':
		case 'Donau':
		case 'DonauBK':
		case 'Taiga':
			return 'IT3';
		case 'KronosS':
		case 'Poseidon':
		case 'ZeusS':
		case 'Helios':
		case 'MinervaSBK':
		case 'MinervaMLK':
		case 'ZeusSZX0':
		case 'ZeusSBK':
		case 'VenusMLK':
			return 'IT5';
		case 'Altair':
		case 'Cypress':
		case 'DenebMLK':
		case 'EagleBKH':
		case 'EagleBKL':
		case 'EagleL':
		case 'EagleH':
		case 'EagleZPlus':
		case 'HeliosMLK':
		case 'Maple':
		case 'MinervaSSBK':
		case 'Rosewood':
		case 'Sparrow':
		case 'SparrowH':
		case 'SparrowBK':
			return 'IT6';
		case 'WHUB':
			return 'workplacehub';
		default:
			return 'IT4';
	}
};

export const arrayify = (value: any) => (Array.isArray(value) ? value : [value]);

// regular display: "11/2/2017, 12:13:42 PM"
// friendlyDisplay: "November 2, 2017"
export const getLocalTime = (
	date: ConstructorParameters<typeof Date>[0],
	friendlyDisplay = false,
	options: Intl.DateTimeFormatOptions | undefined = undefined
) => {
	if (friendlyDisplay) {
		options = { year: 'numeric', month: 'long', day: 'numeric' };
	}
	return date ? new Date(date).toLocaleString(i18next.language, options) : undefined;
};

const deepOmitNullish = (obj: Record<string, any>): Record<string, any> =>
	Object.entries(obj)
		.filter(([_key, value]) => !_.isNil(value))
		.reduce(
			(acc, [key, value]) => ({
				...acc,
				[key]: _.isObject(value) ? deepOmitNullish(value) : value
			}),
			{}
		);

export const isEqualOmitNullish = (a: Record<string, any>, b: Record<string, any>) =>
	_.isEqual(deepOmitNullish(a), deepOmitNullish(b));

// FIXME::need to combine `responseError` and `responseErrors` or just...make this less nonsense
type ResponseErrorsTypeA = { data: { returnCode: string }[] }[];
type ResponseErrorsTypeB = { data: { errors?: { add?: string[]; delete?: string[] } } }[];
const isResponseErrorsTypeA = (
	responses: ResponseErrorsTypeA | ResponseErrorsTypeB | any
): responses is ResponseErrorsTypeA => !!responses?.[0]?.data?.[0]?.returnCode;
export const responseErrors = (responses: ResponseErrorsTypeA | ResponseErrorsTypeB) => {
	if (isResponseErrorsTypeA(responses)) {
		const errors = responses
			.map(({ data }) => data)
			.flat()
			.filter(({ returnCode }) => `${returnCode}`[0] !== '2');
		if (errors.length) console.error(errors);
		return errors;
	}
	const errors = responses
		.map(({ data }) => (data.errors ? [...(data.errors.add || []), ...(data.errors.delete || [])] : []))
		.flat();
	if (errors.length) console.error(errors);
	return errors;
};

type ErrorOutput = {
	payload: {
		error: string;
		message: string;
		statusCode: number;
	};
	statusCode: number;
};
type ResponseError = { output: ErrorOutput }[] | null;
export const responseError = ({ errors }: { errors: ResponseError }) => {
	const error = errors?.[0].output;
	console.error(error);
	return error;
};

export const tDeviceLimitation = (policyItemKey: string, generation: Device['generation'] | undefined, deviceSetting: LicensedPolicyItem | any, { adminpwNotValid, notRemediable, noApiAvailable, cantDisableUnsupportedFeature, patternFilePending, upgradeFirmware }: { adminpwNotValid?: boolean; notRemediable?: boolean; noApiAvailable?: boolean; cantDisableUnsupportedFeature?: boolean, patternFilePending?: boolean; upgradeFirmware?: boolean } ) => {
	const tS = (key: string) => i18n.t([`securitySetting::${key}`, `securitySetting::general;unknown`]);
	// may adjust the order
	if (generation === 'IT6' && (deviceSetting === 'na' || deviceSetting === 'no_scanner')) {
		switch (policyItemKey) {
			case 'tempdata':
				return tS('unsupported;it6')
			case 'hddpass':
			case 'autodoc':
			case 'encryptpdf':
			case 'idandprint':
			case 'securebox':
			case 'mfpsharedfolder':
			case 'jblg/TxMethod':
				return tS('unsupported;microSD')
			case 'ftsvenab':
			case 'fttxenab':
				return tS('unsupported;noMonitorAndRemediate')
			case 'rtscan': // old logs still exist with this
			case 'rtscn/RealTimeScan':
			case 'rtscn/VirusDetectedJob':
			case 'regscan':
				return tS('unsupported;virusScan')
		}
	} else if (generation === 'IT6' && upgradeFirmware) {
		return tS('unsupported;upgradeFirmware')
	}
	if (generation === 'IT5') {
		switch (policyItemKey) {
			case 'regscan':
			case 'rtscan':
				return tS('unsupported;virusScan')
		}
	}

	if (policyItemKey === 'ultilicensed') {
		if (typeof deviceSetting === 'object' && !deviceSetting.supported)
			return tS('unsupported;virusScan')
		if (deviceSetting.licensed && patternFilePending)
			return tS('unsupported;patternfilePending')
	}
	if (cantDisableUnsupportedFeature || noApiAvailable) {
		return tS('unsupported;noApiAvailable')
	}
	if (adminpwNotValid) {
		return tS('unsupported;adminpwNotValid')
	}
	if (notRemediable) {
		return tS('unsupported;notRemediable')
	}

		return '—'

}

// export const tDeviceMoreInformation = (policyItemKey: string, generation: Device['generation'], deviceSetting: LicensedPolicyItem | any, { notRemediable, noApiAvailable, cantDisableUnsupportedFeature }: { notRemediable?: boolean; noApiAvailable?: boolean; cantDisableUnsupportedFeature?: boolean } ) => {
// 	const t = (key: string) => i18n.t([`common::${key}`, '—']);
// 	if (cantDisableUnsupportedFeature || noApiAvailable)
// 		return t(`device:limitation:${policyItemKey}`)
// 	if (generation === 'IT5' && notRemediable)
// 		return t(`device:notRemediable:${policyItemKey}`)
// 	return '—'
// }

export const tPolicyItemVal = ({
	policyItemKey,
	policyItemVal
}: {
	policyItemKey: (typeof allPolicyItems)[0]['key'];
	policyItemVal: PolicyItem['options'][0]['val'] | LicensedPolicyItem | RegScanSchedulePolicyItem | RtScanPolicyItem;
}) => {
	if (_.keyBy(allPolicyItems, 'key')[policyItemKey]?.options[0].tag === '%USER_DEFINED%') {
		return `${policyItemVal}`;
	}

	const tS = (key: string | string[]) =>
		i18n.t([...arrayify(key).map(k => `securitySetting::${k}`), `securitySetting::general;unknown`]);
	const t = (key: string) => i18n.t([`${key}`, `securitySetting::general;unknown`]);

	if (policyItemKey === 'adminpwfresh') {
		return policyItemVal ? tS(`option;age;notExpired`) : tS(`option;age;expired`);
	}

	if (policyItemKey === 'adminpwvalid') {
		return policyItemVal ? tS(`option;passwordStatus;known`) : tS(`option;passwordStatus;unknown`);
	}

	if (policyItemKey === 'adminpw') {
		return policyItemVal ? tS(`option;req;true`) : tS(`general;defaultPasswordFail`);
	}

	if (policyItemKey === 'patternfileversion' && !policyItemVal) {
		return tS(`general;unknown`);
	}

	if (policyItemKey === 'rtscan' || policyItemKey === 'rtscn/RealTimeScan') {
		switch (policyItemVal) {
			case 'On':
			case 'on':
			case true:
				return tS('option;req;true');
			case 'Off':
			case 'off':
			case false:
				return tS('option;noreq;false');
			case 'no_pattern_file':
				return tS(`general;pendingPatternfile`)
		}
	}

	// HACK-ish::I think these values are just kinda wrong?
	if (policyItemKey === 'jblg/OverWrite') {
		if ([true, 'true', 'On', 'on'].includes(policyItemVal as string | boolean)) {
			return tS(`option;jblgoverwrite;true`);
		}
		if ([false, 'false', 'Off', 'off'].includes(policyItemVal as string | boolean)) {
			return tS(`option;jblgoverwrite;false`);
		}
	}

	if (policyItemKey === 'regscan') {
		const policyValue = policyItemVal as RegScanSchedulePolicyItem;
		if (!policyValue) return tS(`general;unknown`);
		if (typeof policyValue === 'string' || typeof policyValue === 'number' || typeof policyValue === 'boolean') {
			if (policyValue == 'no_pattern_file')
				return tS(`general;pendingPatternfile`)
			return tS(`general;na`);
		}

		// FIXME: enable should be string or boolean, not sometimes either.
		// @ts-ignore
		if (!policyValue.enable || policyValue.enable === 'false') {
			return tS('option;noreq;false');
		}

		const time = moment()
			.set('month', 0)
			.set('hour', policyValue.schedule.start_time.hour)
			.set('minutes', policyValue.schedule.start_time.minute);

		switch (policyValue.schedule.cycle) {
			case 'Daily':
				return `${t('policy:regscan schedule:daily:title')}, ${time.format('h:mm A')}`;
			case 'Weekly':
				return `${t('policy:regscan schedule:weekly:title')} on ${t(
					policyValue.schedule.day_of_week
				)}, ${time.format('h:mm A')}`;
			case 'Monthly':
				time.set('date', policyValue.schedule.day_of_month);
				return `${time.format('Do')} of the month, ${time.format('h:mm A')}`;
			case '--':
				return tS('option;noreq;false');
			default:
				return tS(`general;unknown`);
		}
	}

	if (policyItemKey === 'ultilicensed') {
		const policyValue = policyItemVal as LicensedPolicyItem;
		if (!policyValue.supported) return tS('general;kitNotSupported');
		if (!policyValue.licensed) return tS('general;kitNotInstalled');
		if (
			policyValue.licensed &&
			policyValue.is_installed_pattern_file !== undefined &&
			!policyValue.is_installed_pattern_file
		)
			return tS('general;pending');
		return tS('option;req;true');
	}
	if (policyItemKey === adminpwrKey && policyItemVal && typeof policyItemVal === 'string') {
		if (policyItemVal === disablePasswordModeOption) {
			return tS('option;adminpwr/PasswordMode;Disable');
		}
		if (policyItemVal.startsWith(mode1PasswordModeOption)) {
			return tS('option;adminpwr/PasswordMode;Mode1');
		}
		if (policyItemVal.startsWith(mode2PasswordModeOption)) {
			return tS('option;adminpwr/PasswordMode;Mode2');
		}
	}

	if (policyItemVal === 'na' || policyItemVal === 'no_api' || policyItemVal === 'no_scanner') {
		return tS(`general;na`);
	}

	// @ts-ignore
	const tag = _.keyBy(allPolicyItems, 'key')[policyItemKey]?.options.find(
		({ val }: PolicyItem['options'][0]) => `${val}` === `${policyItemVal}`
	)?.tag as PolicyItem['options'][0]['tag'];

	return tS([
		`option;${tag};${policyItemVal}`,
		(() => {
			// HACK::brute force check some truthy/falsy values
			if (['on', 'On'].includes(policyItemVal as string)) {
				return 'option;req;true';
			}

			if (
				[false, -1, 0, 'false', '-1', '0', 'authoff', 'none', 'None', 'off', 'Off'].includes(
					policyItemVal as string | number | boolean
				)
			) {
				return 'option;noreq;false';
			}
			return 'general;unknown';
		})()
	]);
};

export const comparePatternFile = (
	targetItemVal: string,
	referenceItemVal: string,
	t: (text: string) => string
) => {
	const tS = (key: string) => i18n.t([`securitySetting::${key}`, `securitySetting::general;unknown`]);
	const referenceItem = referenceItemVal?.toString().replace(/\s?\([^)]*\)/g, "").trim().split(' ')
	const targetItem = targetItemVal?.toString().replace(/\s?\([^)]*\)/g, "").trim().split(' ')

	if (targetItem.length <= 1)
		return <Trans>
			<strong>{tS(`general;unknown`)}</strong>
		</Trans>
	const newMessage = targetItem.map((item, index) => {
		if (!_.isEqual(item, referenceItem[index]))
			return `<strong>${item}</strong>`
		return item
	})

	return <Trans>
		{t(`${newMessage.join(' ')}`)}
	</Trans>

}

export const TLog = ({ log: { messageKey, info } }: { log: Pick<Log, 'messageKey' | 'info'> }) => {
	const { t } = useTranslation();
	const { t: tL } = useTranslation('log');
	const { t: tS } = useTranslation('securitySetting');

	// const licenseGroups = useSelector(getLicenseGroups);

	// if (licenseGroups) {
	// 	// @ts-ignore
	// 	info = { ...info, tenantName: licenseGroups.find(licenseGroup => licenseGroup.id === info?.tenantId)?.name };
	// }

	if (messageKey === 'deviceSettingRemediated') {
		const allPolicyItemsByKey = _.keyBy(allPolicyItems, 'key');

		const friendlyPolicyItemName = (policyItemKey: PolicyItem['key']) => {
			const parentKey = allPolicyItemsByKey[policyItemKey]?.parentKey;
			return `${parentKey ? `${tS(`setting;${allPolicyItemsByKey[policyItemKey].parentKey};name`)} – ` : ''}${tS(
				`setting;${policyItemKey};name`
			)}`;
		};

		if (info?.msgParm1 === 'ssltls') {
			const SslTlsVersionKeys = ['tls10', 'tls11', 'tls12', 'tls13'] as const;

			// somehow these values are stringified objects but with single quotes - probably from python (alternatively we could import `JSON5.parse`)
			const parseObjectString = (objectString: string) =>
				JSON.parse(
					objectString
						.replaceAll("'", '"')
						.replaceAll('True', 'true')
						.replaceAll('False', 'false'),
					(_key, value) => {
						if (value === 'true') {
							value = true;
						} else if (value === 'false') {
							value = false;
						}
						return value;
					}
				);
			const findMinimum = (sslTlsMap: { [K in typeof SslTlsVersionKeys[number]]: boolean }) =>
				SslTlsVersionKeys.find(key => sslTlsMap[key] === true);
			const findMaximum = (sslTlsMap: { [K in typeof SslTlsVersionKeys[number]]: boolean }) =>
				[...SslTlsVersionKeys].reverse().find(key => sslTlsMap[key] === true);

			// @ts-ignore
			const oldSslTlsMap = parseObjectString(info?.msgParm2);
			// @ts-ignore
			const newSslTlsMap = parseObjectString(info?.msgParm3);

			// @ts-ignore
			info = {
				...info,
				policyItemName: t('policy:ssl tls version:name'),
				oldPolicyItemValue: `${t('policy:ssl tls version:range')} ${tS(
					`setting;${findMinimum(oldSslTlsMap)};name`
				)} - ${tS(`setting;${findMaximum(oldSslTlsMap)};name`)}`,
				newPolicyItemValue: `${t('policy:ssl tls version:range')} ${tS(
					`setting;${findMinimum(newSslTlsMap)};name`
				)} - ${tS(`setting;${findMaximum(newSslTlsMap)};name`)}`
			};
		} else if (info?.msgParm1 === 'regscan') {
			let oldItemValue;
			let newItemValue;
			try {
				oldItemValue = JSON.parse(
					// @ts-ignore
					info?.msgParm2
						?.replaceAll("'", '"')
						.replaceAll('True', 'true')
						.replaceAll('False', 'false')
				);
			} catch (e) {
				console.log(e);
				if (!oldItemValue) oldItemValue = null;
			}

			try {
				newItemValue = JSON.parse(
					// @ts-ignore
					info?.msgParm3
						?.replaceAll("'", '"')
						.replaceAll('True', 'true')
						.replaceAll('False', 'false')
				);
			} catch (e) {
				console.log(e);
				if (!newItemValue) newItemValue = null;
			}

			// @ts-ignore
			info = {
				...info,
				// @ts-ignore
				policyItemName: friendlyPolicyItemName(info.msgParm1!),
				// @ts-ignore
				oldPolicyItemValue: tPolicyItemVal({
					policyItemKey: info.msgParm1,
					policyItemVal: oldItemValue?.regscan
				}),
				// @ts-ignore
				newPolicyItemValue: tPolicyItemVal({
					policyItemKey: info.msgParm1,
					policyItemVal: newItemValue?.regscan
				})
			};
		} else if (info?.msgParm1 === 'rtscan') {
			let oldItemValue;
			let newItemValue;
			try {
				// @ts-ignore
				oldItemValue = JSON.parse(info?.msgParm2?.replaceAll("'", '"'));
				// @ts-ignore
				newItemValue = JSON.parse(info?.msgParm3?.replaceAll("'", '"'));
			} catch (e) {
				console.log(e);
				if (!oldItemValue) oldItemValue = tS(`general;unknown`);
				if (!newItemValue) newItemValue = tS(`general;unknown`);
			}

			// @ts-ignore
			info = {
				...info,
				// @ts-ignore
				policyItemName: friendlyPolicyItemName(info.msgParm1!),
				// @ts-ignore
				oldPolicyItemValue: tPolicyItemVal({
					policyItemKey: info.msgParm1,
					policyItemVal: oldItemValue.rtscan
				}),
				// @ts-ignore
				newPolicyItemValue: tPolicyItemVal({ policyItemKey: info.msgParm1, policyItemVal: newItemValue.rtscan })
			};
		}
		else if (info?.msgParm1 === 'patternfileversion') {
			const date = /[0-9]{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2][0-9]|3[0-1])/
			const time = /(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/
			const version = /\s[0-9]*\s/

			const { msgParm2, msgParm3 } = info
			const prevMsg =  msgParm2 ? msgParm2?.replace(/\s?\([^)]*\)/g, "").trim() : undefined;
			const currMsg = msgParm3 ? msgParm3?.replace(/\s?\([^)]*\)/g, "").trim() : undefined;
			const oldDate = prevMsg ? prevMsg?.match(date) : undefined
			const oldTime = prevMsg ? prevMsg?.match(time) : undefined
			const oldPatternfile = prevMsg ? prevMsg?.match(version) : undefined
			const newDate = currMsg ? currMsg?.match(date) : undefined
			const newTime = currMsg ? currMsg?.match(time) : undefined
			const newPatternfile = currMsg ? currMsg?.match(version) : undefined
			const dateChanged = !_.isEqual(oldDate?.[0], newDate?.[0])
			const timeChanged = !_.isEqual(oldTime?.[0], newTime?.[0])
			const versionChanged = !_.isEqual(oldPatternfile?.[0], newPatternfile?.[0])

			let newValue: string | string[] = ''

			try {
				const getStrDifference = (str1: string | undefined, str2: string | undefined) => {
					if (!str1)
						return str2 || undefined
					if (!str2)
						return undefined
					const msg1 = str1.split(' ');
						const msg2 = str2.split(' ')
					return msg2.reduce((diff: string[], word: string, pos: number) => {
						word != msg1[pos] && diff.push(word);
						return diff
					}, [])
				}
				if ((!prevMsg || prevMsg == '') && currMsg !== undefined) {
					newValue = currMsg.split(' ')

				}
				else if (currMsg !== undefined) {
					// @ts-ignore
					newValue = getStrDifference(prevMsg, currMsg)

				}

				// @ts-ignore
				info = {
					...info,
					// @ts-ignore
					policyItemName: friendlyPolicyItemName(info.msgParm1!),
					// @ts-ignore
					// oldPolicyItemValue: tPolicyItemVal({ policyItemKey: info.msgParm1, policyItemVal: Array.isArray(oldPatternfile) ? prevValue.trimEnd() : null }),
					// @ts-ignore
					newPolicyItemValue: tPolicyItemVal({
						// @ts-ignore
						policyItemKey: info?.msgParm1,
						// @ts-ignore
						policyItemVal: Array.isArray(newValue) ? newValue.join(' ') : null
					})
				};

				if (!dateChanged && timeChanged) {
					// @ts-ignore
					[newValue].unshift(newDate?.[0].toString)
					// @ts-ignore
					info = {
						...info,
						// @ts-ignore
						policyItemName: friendlyPolicyItemName(info.msgParm1!),
						// @ts-ignore
						// oldPolicyItemValue: tPolicyItemVal({ policyItemKey: info.msgParm1, policyItemVal: Array.isArray(oldPatternfile) ? prevValue.trimEnd() : null }),
						// @ts-ignore
						newPolicyItemValue: tPolicyItemVal({
							// @ts-ignore
							policyItemKey: info?.msgParm1,
							// @ts-ignore
							policyItemVal: Array.isArray(newValue) ? newValue.join(' ') : null
						}),
						date: newDate?.[0]
					};
					return (
						<Trans i18nKey='log:patternFileUpdatedTime'>
							<>{tL('patternFileUpdatedTime', info)}</>
						</Trans>
					)
				}
				if (dateChanged && !timeChanged) {

					// @ts-ignore
					info = {
						...info,
						// @ts-ignore
						policyItemName: friendlyPolicyItemName(info.msgParm1!),
						// @ts-ignore
						oldPolicyItemValue: tPolicyItemVal({ policyItemKey: info.msgParm1, policyItemVal: '' }),
						// @ts-ignore
						date: newDate?.[0],
						time: newTime?.[0],
						version: newPatternfile?.[0].trim()
					};
					if (versionChanged) {
						return (
							<Trans i18nKey='log:patternFileUpdatedDateVersion'>
								<>{tL('patternFileUpdatedDateVersion', info)}</>
							</Trans>
						)
					}
						return (
							<Trans i18nKey='log:patternFileUpdatedDate'>
								<>{tL('patternFileUpdatedDate', info)}</>
							</Trans>
						)

				}
				if (oldDate === undefined || oldTime === undefined || oldPatternfile === undefined) {
					return (
						<Trans i18nKey='log:patternFileUpdated'>
							<>{tL('patternFileUpdated', info)}</>
						</Trans>)
				}

				return (
					<Trans i18nKey='log:patternFileUpdatedBold'>
						<>{tL('patternFileUpdatedBold', info)}</>
					</Trans>
				)
			} catch (e) {
				console.log(e)
			}
		}
		else {
			const normalizedNewPolicyVal =
				info && typeof info?.msgParm3 === 'string'
					? info.msgParm3.replaceAll('False', 'false').replaceAll('True', 'true')
					: info?.msgParm3;
			// @ts-ignore
			info = {
				...info,
				// @ts-ignore
				policyItemName: friendlyPolicyItemName(info.msgParm1!),
				// @ts-ignore
				oldPolicyItemValue: tPolicyItemVal({ policyItemKey: info.msgParm1, policyItemVal: info.msgParm2 }),
				// @ts-ignore
				newPolicyItemValue: tPolicyItemVal({
					// @ts-ignore
					policyItemKey: info?.msgParm1,
					// @ts-ignore
					policyItemVal: normalizedNewPolicyVal
				})
			};
		}
		return <>{tL(messageKey, info)}</>;
	}

	if (messageKey.startsWith('taskStatus')) {
		messageKey = `taskStatus${info!.status!.toUpperCase()}`;
		// @ts-ignore
		info = { ...info, task: info?.task?.toLowerCase() };
	}

	return <>{tL(messageKey, info)}</>;
};

/**
 *
 * @param length Length of the string to be generated
 * @param mask
 * ### character mask
 *
 * * a = lowercase letter
 *
 * * A = uppercase letters,
 *
 * * \# = numbers
 *
 * * \! = symbols
 * @returns generated string
 */
export const generateString = (length = 32, mask = 'aA#!') => {
	if (mask.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
	if (mask.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	if (mask.indexOf('#') > -1) mask += '0123456789';
	if (mask.indexOf('!') > -1) mask += '~`!@$%^&*()_+-={}[]:;<>?,.|';
	let result = '';
	for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
	return result;
};

export const to24Hour = (time: string | undefined): string | void => {
	if (!time) return undefined;
	return moment(`${moment().format('MM/DD/YYYY')} ${time}`).format('HH:mm');
};
export const to12Hour = (time: string | undefined): string | void => {
	if (!time) return undefined;
	return moment(`${moment().format('MM/DD/YYYY')} ${time}`).format('hh:mm A');
};

export const toMoment = (time: string) => {
	return moment(`${moment().format('MM/DD/YYYY')} ${time}`);
};

export const toScheduleString = (schedule: RegScanSchedule) => {
	const tS = (key: string) => i18n.t([`securitySetting::${key}`, `securitySetting::general;unknown`]);
	const t = (key: string) => i18n.t([`${key}`, `securitySetting::general;unknown`]);
	const time = moment()
		.set('month', 0)
		.set('hour', schedule.start_time.hour)
		.set('minutes', schedule.start_time.minute);

	switch (schedule.cycle) {
		case 'Daily':
			return `${t('policy:regscan schedule:daily:title')}, ${time.format('h:mm A')}`;
		case 'Weekly':
			return `${t('policy:regscan schedule:weekly:title')} on ${t(schedule.day_of_week)}, ${time.format(
				'h:mm A'
			)}`;
		case 'Monthly':
			time.set('date', schedule.day_of_month);
			return `${time.format('Do')} of the month, ${time.format('h:mm A')}`;
		default:
			return tS(`general;unknown`);
	}
};
