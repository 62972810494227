// TEMP HACKS (HOPEFULLY)
import allPolicyItems, { prohibitedWordkey } from 'app/main/policies/allPolicyItems';
import { PolicyItemSetting, MpDevice } from 'app/store/types';

// HACK::cartoonishly fragile (and dangerous) hack which skips values that are suspected to be a name
const nonNumericStringCheckHack = (str: any) =>
	typeof str === 'string' &&
	(str.toLowerCase().startsWith('name') ||
		str.toLowerCase().endsWith('name') ||
		str.includes('Name') ||
		str.includes('_name'));

// HACK::have correct data types from the backend
export const parseJsonHack = (json: any) =>
	JSON.parse(JSON.stringify(json), (key, value) => {
		if (value === 'true') {
			value = true;
		} else if (value === 'false') {
			value = false;
		} else if (key?.startsWith(prohibitedWordkey)) {
			value = String(value);
		} else if (typeof value === 'string' && !Number.isNaN(+value) && !nonNumericStringCheckHack(key)) {
			value = +value;
		}
		// HACK::leave some arrays alone (this is a fragile mess...)
		if (['blacklist', 'alertCell'].includes(key) && Array.isArray(value)) {
			value = value.map(val => `${val}`);
		}
		return value;
	});

export const reverseParseJsonHack = (json: any) =>
	json === undefined
		? undefined
		: JSON.parse(JSON.stringify(json), (key, value) => {
				if (value === true) {
					value = 'true';
				} else if (value === false) {
					value = 'false';
				} else if (typeof value === 'number') {
					value = `${value}`;
				}
				return value;
		  });

// HACK::merge the modified policy item requirements with the full policy item data (need to send all data)
export const enrichPolicyItemsHack = (policyItems?: { [policyItemKey: string]: PolicyItemSetting }) =>
	policyItems
		? allPolicyItems
				.filter(policyItem => policyItems[policyItem.key] !== undefined)
				.map(policyItem => ({
					key: policyItem.key,
					options: policyItem.options,
					requirement: policyItems[policyItem.key].requirement,
					remediation: policyItems[policyItem.key].remediation
				}))
		: undefined;

export const getDevicesDataHack = (licenseGroupId: string, serials: string[], mpDevices: MpDevice[]) =>
	serials.map(serial => {
		const mpDevice = mpDevices.find(device => device.serial === serial)!;
		return reverseParseJsonHack({
			tenantId: licenseGroupId,
			deviceId: serial,
			deviceInfo: {
				serialNumber: serial,
				deviceType: {
					family: mpDevice.family
				}
			},
			// TEMP::don't pass productLine - which is already added on backend
			// name: `${mpDevice.productLine} ${mpDevice.name}`
			name: mpDevice.name,
			friendlyName: mpDevice.friendlyName,
			publicKey: mpDevice.publicKey
		});
	});

export const getUsersDataHack = (licenseGroupId: string, emails: string[]) =>
	emails.map(email => {
		// const mpDevice = mpDevices.find(device => device.serial === serial)!;
		return reverseParseJsonHack({
			tenantId: licenseGroupId,
			userId: email,
			email,
			active: true,
			firstName: 'John',
			lastName: 'Smith',
			settings: { autoDeviceAssign: false },
			permissions: { is_customer_admin: true, can_install_mfp_app: true, can_invite_user: true },
			roleId: 'default'
		});
	});
